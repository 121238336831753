import React from 'react';
// eslint-disable-next-line @next/next/no-document-import-in-page
import type { DocumentContext } from 'next/document';
import ErrorPage, { ErrorProps } from 'next/error';
import Bugsnag from ':src/lib/bugsnag';

export default class Page extends React.Component<ErrorProps> {
  static async getInitialProps(ctx: DocumentContext) {
    if (ctx.err) Bugsnag.notify(ctx.err);
    return ErrorPage.getInitialProps(ctx);
  }

  render() {
    return <ErrorPage statusCode={this.props.statusCode} />;
  }
}
